import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";
import {
  type UserTransaction,
  type CoinType,
  type UserStats,
  type ListedUser,
  type User,
  type UpdateUserEmail,
  type UpdateUserPassword,
  type UserWalletDetail,
} from "utils/types/user.type";

export const useGetLoggedInUser = () =>
  useQuery<User, Error>(
    ["getLoggedInUser"],
    async () => await client(`auth/getLoggedInUser`)
  );

export const useGetTransactions = (
  status: "Completed" | "Pending" | "Rejected",
  type?: "Deposit" | "Swap" | "Withdraw" | "Withdraw Fiat"
) =>
  useQuery<UserTransaction[], Error>(
    ["getTransactions", status],
    async () =>
      await client(
        `wallet/getTransactionsByAdmin?status=${status}${
          type ? `&type=${type}` : ""
        }`
      )
  );

export const useGetStats = () =>
  useQuery<UserStats, Error>(
    ["getStats"],
    async () => await client(`wallet/getStats`)
  );

export const useGetFee = () =>
  useQuery<any, Error>(["getFee"], async () => await client(`wallet/getFee`));

export const useGetUserList = (isAdmin?: boolean) =>
  useQuery<ListedUser[], Error>(
    ["getUserList"],
    async () =>
      await client(`auth/getAllUsers${isAdmin ? "?filterOnlyAdmins=true" : ""}`)
  );

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    { userId: string; isActive: boolean; isBlocked: boolean }
  >(async (data) => await client(`auth/updateUserActive`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getUserList"]);
    },
  });
};

export const useGetCoins = () =>
  useQuery<CoinType[], Error>(
    ["getCoins"],
    async () => await client(`coins/getCoins`)
  );

export const useGetReserves = () =>
  useQuery<any, Error>(
    ["getReserves"],
    async () => await client(`wallet/getReserves`)
  );

export const useGetSwapCoins = () =>
  useQuery<CoinType[], Error>(
    ["getSwapCoins"],
    async () => await client(`coins/getCoins?isOnBitfinex=true`)
  );

export const useUpdateCoinPrice = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, { price: number; coinId: string }>(
    async (data) => await client(`coins/updateCoinPrice`, { data }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getCoins"]);
      },
    }
  );
};

export const useSetFee = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    { feeName: string; feePercentage: number }
  >(async (data) => await client(`wallet/setFee`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getFee"]);
    },
  });
};

export const useUpdateEmail = () =>
  useMutation<unknown, Error, UpdateUserEmail>(
    async (data) => await client(`auth/changeEmail`, { data })
  );

export const useUpdatePassword = () =>
  useMutation<unknown, Error, UpdateUserPassword>(
    async (data) => await client(`auth/changePassword`, { data })
  );

export const useUpdateAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    { userId: string; role: "User" | "Admin" | string }
  >(async (data) => await client(`auth/updateUserRole`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getUserList"]);
    },
  });
};

export const useGetUserWalletBalance = (id?: string) =>
  useQuery<UserWalletDetail, Error>(
    ["getUserWalletBalance", id],
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    async () => await client(`wallet/getUserWalletWithBalance/${id}`),
    { enabled: !!id }
  );

export const useUpdateTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, { transactionId: string; status: string }>(
    async (data) => await client(`wallet/updateTransaction`, { data }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getTransactions", "Pending"]);
      },
    }
  );
};

export const useGetKycTransactionLimit = (kycName: string) =>
  useQuery<any, Error>(
    ["getKycTransactionLimit", kycName],
    async () => await client(`wallet/getKycTransactionLimit?kycName=${kycName}`)
  );

export const useSetKycTransactionLimit = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    {
      name: string;
      swapUpperLimit?: number;
      withdrawUpperLimit?: number;
      isActive: boolean;
    }
  >(async (data) => await client(`wallet/setKycTransactionLimit`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getKycTransactionLimit"]);
    },
  });
};

export const useSetUserTransactionLimit = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    {
      userId: string;
      swapUpperLimit?: number;
      withdrawUpperLimit?: number;
      blackList: boolean;
    }
  >(async (data) => await client(`wallet/setUserTransactionLimit`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getUserList"]);
    },
  });
};

export const useAddCoin = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    {
      name: string;
      symbol: number;
      isToken: boolean;
      tokenPriceFrom: string;
      coinNameId: string;
      icon: string;
      contractAddress: string;
      decimal: number;
      networkId: string;
      unit: string;
      currencyYouCanSwap: string[];
      swapped: boolean;
      isStableCoin: boolean;
      tokenWithdrawn: string;
    }
  >(async (data) => await client(`coins/addCoin`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getCoins"]);
    },
  });
};

export const useUpdateCoin = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    {
      coinId: string;
      name: string;
      symbol: string;
      isToken: boolean;
      tokenPriceFrom: string;
      coinNameId: string;
      icon: string;
      contractAddress: string;
      decimal: number;
      networkId: string;
      unit: string;
      currencyYouCanSwap: string[];
      swapped: string;
      isStableCoin: boolean;
      tokenWithdrawn: string[];
    }
  >(
    async (data) => {
      console.log(data);
      const { coinId, ...rest } = data;
      return await client(`coins/updateCoin?coinId=${coinId}`, { data: rest });
    },
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getCoins"]);
      },
    }
  );
};

export const useGetNetworks = () =>
  useQuery<any, Error>(
    ["getNetworks"],
    async () => await client(`coins/getNetworks`)
  );

export const useUpdateCoinAvailability = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    {
      coinId: string;
      isActive: boolean;
    }
  >(
    async (data) => {
      const { coinId, ...rest } = data;
      return await client(`coins/updateCoinAvailability?coinId=${coinId}`, {
        data: rest,
      });
    },
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getCoins"]);
      },
    }
  );
};
